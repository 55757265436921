import { useState } from "react";

const Header = (params: any) => {
    const backgroundImageUrl = String(process.env.REACT_APP_WEBSITE_URL) + String(process.env.REACT_APP_WEBSITE_URL_IMAGE_BACKGROUND);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const showEventsMenu: boolean = (process.env.REACT_APP_WEBSITE_HEADER_EVENTS_SHOW === 'true') ? true : false;
    const showContactMenu: boolean = (process.env.REACT_APP_WEBSITE_HEADER_CONTACT_SHOW === 'true') ? true : false;
    const showQuestionsMenu: boolean = (process.env.REACT_APP_WEBSITE_HEADER_QUESTIONS_SHOW === 'true') ? true : false;
    //console.log({ShowHeader: process.env.REACT_APP_WEBSITE_HEADER_EVENTS_SHOW,  showHeade: showEventsMenu});
    return (
        <header className="text-white">

            {/*<!-- Navigation-->*/}
            <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
                <div className="container px-4 px-lg-5">
                    <a className="navbar-brand" href="/">{process.env.REACT_APP_WEBSITE_NAME}</a>
                    <button onClick={handleNavCollapse} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarResponsive">
                        <ul className="navbar-nav ms-auto py-4 py-lg-0">
                            <li className="nav-item"><a className="nav-link px-lg-3 py-3 py-lg-4" href="/">Home</a></li>
                            {showEventsMenu &&
                                <li className="nav-item"><a className="nav-link px-lg-3 py-3 py-lg-4" href="/events">Events</a></li>
                            }
                            {showQuestionsMenu &&
                                <li className="nav-item"><a className="nav-link px-lg-3 py-3 py-lg-4" href="/questions">Ask A Question</a></li>
                            }
                            {showContactMenu &&
                                <li className="nav-item"><a className="nav-link px-lg-3 py-3 py-lg-4" href="/contact">Contact</a></li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>

            {/* <!-- Page Header--> */}
            <header className="masthead" style={{ backgroundImage: 'url(' + backgroundImageUrl + ')' }}>
                <div className="container position-relative px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-md-10 col-lg-8 col-xl-7">
                            <div className="site-heading">
                                <h1 style={{ 'textTransform': 'capitalize' }}>{params.title}</h1>
                                <div className="subheading" dangerouslySetInnerHTML={{ __html: String(process.env.REACT_APP_WEBSITE_DESCRIPTION) }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>

        </header>
    )
};
export default Header;

import React from 'react';
import Header from '../components/Header';
import { CacheGet } from '../providers/Cache';

class Gallery extends React.Component<any, any> {
    API_FORM_URL = 'http://localhost:3010/api/gallery/' + process.env.REACT_APP_WEBSITE_KEY_PRIVATE; 
    //API_FORM_URL = process.env.REACT_APP_GALLERY_URL_API + '/gallery/' + process.env.REACT_APP_WEBSITE_KEY_PRIVATE;

    state: Readonly<any> = {
        isValid: false,
        authentication: null,
        images: [],
        Name: '',
        Description: '',
        Category: '',
        Tags: ''
    }

    constructor(props: any) {
        super(props);
        const cache = CacheGet(String(process.env.REACT_APP_WEBSITE_KEY_PRIVATE)) || {};
        this.state = {
            isValid: false,
            authentication: cache ? cache.authentication : {},
            images: [],
            Name: '',
            Description: '',
            Category: '',
            Tags: ''
        }
    }

    componentDidMount() {
        this.listAllImages();
    }

    validate = (data: any) => {
        if (data && data.Name && data.Description && data.Category && data.Tags) {
            return true;
        }
        else {
            return false;
        }
    }

    onChangeHandle = (event: any) => {
        const value: string = event.target.value;
        const target: string = event.target.id;
        const id = String(target);
        const data: any = {...this.state};
        data[id] = value;

        const isValid = this.validate(data);
        data['isValid'] = isValid;

        this.setState(data);
        //console.log({state: this.state, id: id, value: value})
    }

    handleTextAreaAutogrow = (e: any) => {
        // Reset field height
        e.target.style.height = 'inherit';

        // Get the computed styles for the element
        const computed = window.getComputedStyle(e.target);

        // Calculate the height
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            + parseInt(computed.getPropertyValue('padding-top'), 10)
            + e.target.scrollHeight
            + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        e.target.style.height = `${height}px`;
    }

    listAllImages = () => {
        fetch(this.API_FORM_URL, {
            method: 'GET',
            headers: { ...this.state.authentication }
        })
            .then(res => res.text())
            .then(data => {
                const images = JSON.parse(data);
                //console.log({ List_All_Images: images });
                this.setState({
                    images: images
                })
            });
    }

    onChangeImageHandle = (event: any) => {
        //const files = Array.from(event.target.files);
        const file = event.target.files[0];
        const fileName = file.name || 'image.png';
        //const fileType = file.type || 'image/png';
        const fileSize = file.size / 1024;
        if (!this.state.isValid || ((fileSize * 1024) > 100000)) {
            return;
        }

        const formData = new FormData();

        formData.append('file', file, fileName);
        /*
        formData.append('ITCC_WebsiteID', this.state.authentication.websiteId);
        formData.append('ITCC_BlogID', String(this.state.ITCC_BlogID));
        formData.append('ITCC_UserID', String(this.state.authentication.userId));
        formData.append('Permalink', this.state.Permalink);
        formData.append('file', file, fileName);
        */
        formData.append('Name', this.state.Name);
       formData.append('Description', this.state.Description);
       formData.append('Category', this.state.Category);
       formData.append('Tags', this.state.Tags);

        const headers = {...this.state.authentication};
        /*
        headers['Name'] = this.state.Name;
        headers['Description'] = this.state.Description;
        headers['Category'] = this.state.Category;
        headers['Tags'] = this.state.Tags;
        */
        
        fetch(this.API_FORM_URL, {
            method: 'POST',
            headers: headers,
            body: formData
        })
            .then(res => res.text())
            .then(images => {
                // console.log({ images: images });
                this.listAllImages();
            });

        //console.log({onChangeImageHandle: {file:file, fileName:fileName, filzeSize: fileSize, formData: formData}})
    }

    render() {
        return (
            <section className="Gallery">
                <Header title={'Gallery'}></Header>
                {/* <!-- BEGIN CONTAINER  -->} */}
                <div className="container px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-md-10 col-lg-8 col-xl-7">

                            {/* <!-- BEGIN FORM  -->} */}
                            <form method="post">
                                <div className="row g-0">
                                    <div className="col-12">
                                    </div>
                                    <div className="col-12 form-box__content">
                                        <div id="box-title">
                                            <h3 className="text-center">Gallery</h3>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label style={{ fontWeight:'bold', color: !(this.state.Name || this.state.Name ===0) ? 'red' : '' }}>Image Name:</label>
                                                <input value={this.state.Name} onChange={this.onChangeHandle.bind(this)} type="text" className="form-control input-md" id="Name" name="Name" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label style={{ fontWeight:'bold', color: !(this.state.Description || this.state.Description ===0) ? 'red' : '' }}>Image Description:</label>
                                                <textarea onKeyUp={this.handleTextAreaAutogrow.bind(this)} value={this.state.Description} onChange={this.onChangeHandle.bind(this)} id="Description" rows={1} className="form-control input-md" placeholder="Summary"></textarea>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label>Image Category:</label>
                                                <input value={this.state.Category} onChange={this.onChangeHandle.bind(this)} type="text" className="form-control input-md" id="Category" name="Category" />
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Image Tags:</label>
                                                <input value={this.state.Tags} onChange={this.onChangeHandle.bind(this)} type="text" className="form-control input-md" id="Tags" name="Tags" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label>Choose an Image:</label>
                                                <input disabled={!this.state.isValid} onChange={this.onChangeImageHandle.bind(this)} type="file" className="form-control input-md" id="PostDate" name="PostDate" />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </form>
                            {/* <!-- END FORM  -->} */}

                            {/* <!-- BEGIN HIDE FORM  -->} */}
                            <div className="row mt-3 mb-3 p-3">
                                <div className="col-md-2"></div>
                                <div className="col-md-8">
                                    <h3>Your image has been uploaded ...</h3>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            {/* <!-- END HIDE FORM  -->} */}

                        </div>
                    </div>
                    <div className="card-group">
                        <div className="row">
                            {this.state.images.map((item: any, index: number) => {
                                const url = process.env.REACT_APP_GALLERY_URL_API + '/' + item.Description;
                                return (
                                    <div key={index} className='col-4'>
                                        <div className='card'>
                                            <img src={url} className="card-img-top" alt="..."></img>
                                            <div className="card-body">
                                                <h5 className="card-title">...</h5>
                                                <p className="card-text"></p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
                {/* <!-- END CONTAINER  -->} */}

            </section>
        );
    }
};
export default Gallery;
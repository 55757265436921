export type CacheStore = 
{
    key  :string;
    authentication: any;
}

export const CacheSave = (data: CacheStore) => {
    localStorage.setItem(data.key, JSON.stringify(data));
}

export const CacheGet = (key: string) => {
    const data = localStorage.getItem(key);
    if(data){
        return JSON.parse(data);
    }
    else return null;
}


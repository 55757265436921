//import * as bootstrap from 'bootstrap';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './styles/index.scss';

// const Home = lazy(() => import('./routes/Home'));
import Footer from './components/Footer';
import Gallery from './pages/Gallery';
const Blog = lazy(() => import('./pages/Blog'));
const Crud = lazy(() => import('./pages/Crud'));
const Login = lazy(() => import('./pages/Login'));
const Contact = lazy(() => import('./pages/Contact'));
const AskAQuestion = lazy(() => import('./pages/AskAQuestion'));
const EventbritePost = lazy(() => import('./pages/Eventbrite/Post'));
const Post = lazy(() => import('./pages/Post'));
const Events = lazy(() => import('./pages/Events'));
const Locations = lazy(() => import('./pages/Locations'));
const Privacy = lazy(() => import('./pages/Privacy'));
const MembersPost = lazy(() => import('./pages/Members/Post'));
const ConfirmYourRequest = lazy(() => import('./pages/Members/ConfirmYourRequest'));

class App extends React.Component {

  constructor(props: any) {
    super(props);

    this.state = {
      show: false
    };
  }

  render() {
    document.title = String(process.env.REACT_APP_WEBSITE_NAME);
    return (
      <section className="App" id="app-name">
        <BrowserRouter>
          <Suspense fallback={
            <section>
              <div className="d-flex justify-content-center" >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </section>
          }>
            <Switch>
              <Route path="/" component={Blog} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/contact" component={Contact} exact />
              <Route path="/locations" component={Locations} exact />
              <Route path="/crud" component={Crud} exact />
              <Route path="/events" component={Events} exact />
              <Route path="/eventbrite/register-confirm/:slug">
                <EventbritePost EventbriteType={'Register-Confirm'}/>
              </Route>
              <Route path="/eventbrite/thank-you/:slug">
                <EventbritePost EventbriteType={'Thank-You'}/>
              </Route>
              <Route path="/members/register-confirm/:slug" render={({ location }) =>
                <MembersPost MembersType={'Register-Confirm'} location={location}  />
              } 
              />
              <Route path="/members/thank-you/:slug" render={({ location }) =>
                <MembersPost MembersType={'Thank-You'} location={location} />
              } 
              />
              <Route path="/members/confirm-request" component={ConfirmYourRequest} exact />
              <Route path="/questions/:slug?" render={({ location }) =>
                <AskAQuestion MembersType={'ask--a-question'} location={location}  />
              } 
              />
              <Route path="/gallery" component={Gallery} exact />
              <Route path="/privacy" component={Privacy} exact />
              <Route path="/:slug" component={Post} />
            </Switch>
          </Suspense>
          <div className="container-fluid mt-5 clearfix">
            <Footer></Footer>
          </div>
        </BrowserRouter>
      </section>
    );
  }
}

export default App;
